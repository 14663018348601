.features {
  width: auto;
  height: auto;
  display: inline-flex;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 12px;
}
.features > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 2px);
  width: calc(100% + 2px);
}
.features > :global(.__wab_flex-container) > *,
.features > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.features > :global(.__wab_flex-container) > picture > img,
.features
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 2px;
}
.featuresactive {
  background: #8bcdf333;
}
.features:active {
  background: #8bcdf333;
}
.featuresactive:active {
  box-shadow: inset 0px 1px 4px 2px #0f2a4126;
}
.text3 {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  position: relative;
  user-select: none;
}
.text3active {
  color: var(--token-gaowRD3VsD3w);
}
.features:hover .text3 {
  color: var(--token-pkN2y4iq-viG);
}
.features:active .text3 {
  color: var(--token-gaowRD3VsD3w);
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #003c70;
  width: 16px;
  height: 16px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
