.col {
  height: auto;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  width: 100%;
  flex-shrink: 1;
  position: relative;
  min-width: 0;
}
.col > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.col > :global(.__wab_flex-container) > *,
.col > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.col > :global(.__wab_flex-container) > picture > img,
.col > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
.header {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.header > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.header > :global(.__wab_flex-container) > *,
.header > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.header > :global(.__wab_flex-container) > picture > img,
.header
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.heading {
  font-family: var(--rubik);
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: var(--token-vF1ggEL4NG72);
  display: block;
  align-self: stretch;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heading {
    font-size: 24px;
    line-height: 32px;
  }
}
.text {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  display: block;
  align-self: stretch;
  position: relative;
  width: 100%;
  max-width: 560px;
  min-width: 0;
}
.actions {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.actions > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.actions > :global(.__wab_flex-container) > *,
.actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.actions > :global(.__wab_flex-container) > picture > img,
.actions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
@media (max-width: 576px) {
  .actions > :global(.__wab_flex-container) {
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 576px) {
  .actions > :global(.__wab_flex-container) > *,
  .actions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .actions > :global(.__wab_flex-container) > picture > img,
  .actions
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
.button:global(.__wab_instance) {
  height: 48px;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button:global(.__wab_instance) {
    height: auto;
    width: auto;
  }
}
.svg__jlSkZ {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__pGDx {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-9T9NC2lhv1Ej);
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__pGDx {
    white-space: pre;
  }
}
.svg__fbnLw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.button2:global(.__wab_instance) {
  height: 48px;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button2:global(.__wab_instance) {
    height: auto;
    width: auto;
  }
}
.svg__irzKw {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text__wsCq3 {
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--token-vF1ggEL4NG72);
}
.svg__exemg {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
