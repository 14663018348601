.footerTop {
  height: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 0;
}
.footerTop > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.footerTop > :global(.__wab_flex-container) > *,
.footerTop > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.footerTop > :global(.__wab_flex-container) > picture > img,
.footerTop
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
@media (max-width: 1024px) {
  .footerTop {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .footerTop > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 1024px) {
  .footerTop > :global(.__wab_flex-container) > *,
  .footerTop > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .footerTop > :global(.__wab_flex-container) > picture > img,
  .footerTop
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerTop {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerTop > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerTop > :global(.__wab_flex-container) > *,
  .footerTop > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .footerTop > :global(.__wab_flex-container) > picture > img,
  .footerTop
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.footerTopNav:global(.__wab_instance) {
  position: relative;
  width: 100%;
  min-width: 0;
}
.footerTopDownload:global(.__wab_instance) {
  position: relative;
}
@media (max-width: 1024px) {
  .footerTopDownload:global(.__wab_instance) {
    margin-top: calc(30px + 32px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: calc(0px + 0px) !important;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerTopDownload:global(.__wab_instance) {
    margin-top: calc(30px + 32px) !important;
    margin-left: calc(0px + 0px) !important;
  }
}
