.submenuItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 0;
  padding: 8px 72px 8px 32px;
  border-left: 4px solid var(--token-9T9NC2lhv1Ej);
}
.submenuItem > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.submenuItem > :global(.__wab_flex-container) > *,
.submenuItem > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.submenuItem > :global(.__wab_flex-container) > picture > img,
.submenuItem
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.submenuItemactive {
  border-left: 4px solid var(--token-pkN2y4iq-viG);
}
.link {
  font-family: var(--rubik);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-vF1ggEL4NG72);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.submenuItem:hover .link {
  color: var(--token-gaowRD3VsD3w);
}
.text {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-sJr9sbZwbyqK);
  position: relative;
  width: 100%;
  height: auto;
  max-width: 286px;
  min-width: 0;
}
