.navs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-width: 0;
}
.desktop {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 600px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
.generalStayInTouch {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.generalStayInTouch > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.generalStayInTouch > :global(.__wab_flex-container) > *,
.generalStayInTouch > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.generalStayInTouch > :global(.__wab_flex-container) > picture > img,
.generalStayInTouch
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.general {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.general > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.general > :global(.__wab_flex-container) > *,
.general > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.general > :global(.__wab_flex-container) > picture > img,
.general
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.head {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
}
.items {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.items > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.items > :global(.__wab_flex-container) > *,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items > :global(.__wab_flex-container) > picture > img,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.link {
  display: block;
  align-self: stretch;
  position: relative;
}
.link__ea7W {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link2 {
  display: block;
  align-self: stretch;
  position: relative;
}
.link__uh2YX {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link3 {
  display: block;
  position: relative;
  align-self: stretch;
}
.link___1Yu16 {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link5 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__bmFdC {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.link6 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__crPtM {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.stayInTouch {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  margin-top: calc(16px + 24px) !important;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.stayInTouch > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.stayInTouch > :global(.__wab_flex-container) > *,
.stayInTouch > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.stayInTouch > :global(.__wab_flex-container) > picture > img,
.stayInTouch
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.head2 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
}
.links {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.links > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.links > :global(.__wab_flex-container) > *,
.links > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.links > :global(.__wab_flex-container) > picture > img,
.links > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 24px;
}
.x {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__aP9Nl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__aP9Nl > picture > img {
  object-fit: cover;
}
.fb {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__xWwyn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__xWwyn > picture > img {
  object-fit: cover;
}
.ig {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__pg7AW {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__pg7AW > picture > img {
  object-fit: cover;
}
.li {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__oDhLi {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__oDhLi > picture > img {
  object-fit: cover;
}
.th {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__bVu6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__bVu6 > picture > img {
  object-fit: cover;
}
.resources2 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.resources2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.resources2 > :global(.__wab_flex-container) > *,
.resources2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.resources2 > :global(.__wab_flex-container) > picture > img,
.resources2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.resources {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.resources > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.resources > :global(.__wab_flex-container) > *,
.resources > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.resources > :global(.__wab_flex-container) > picture > img,
.resources
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.head5 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
}
.items4 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.items4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.items4 > :global(.__wab_flex-container) > *,
.items4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items4 > :global(.__wab_flex-container) > picture > img,
.items4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.link36 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__mTGul {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link38 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__pSt52 {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link37 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__rEzin {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.mobile {
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  display: none;
}
.mobile > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.mobile > :global(.__wab_flex-container) > *,
.mobile > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mobile > :global(.__wab_flex-container) > picture > img,
.mobile
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobile {
    display: flex;
  }
}
.generaSolutions {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.generaSolutions > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.generaSolutions > :global(.__wab_flex-container) > *,
.generaSolutions > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.generaSolutions > :global(.__wab_flex-container) > picture > img,
.generaSolutions
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.general3 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.general3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.general3 > :global(.__wab_flex-container) > *,
.general3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.general3 > :global(.__wab_flex-container) > picture > img,
.general3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.head11 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
}
.items9 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.items9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.items9 > :global(.__wab_flex-container) > *,
.items9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items9 > :global(.__wab_flex-container) > picture > img,
.items9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.link81 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__gCjg {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link82 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__yzzJf {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link83 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__n8NP {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link85 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__dv2C {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.link86 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__qg4C {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.stayInTouch3 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  margin-top: calc(16px + 24px) !important;
}
.stayInTouch3 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.stayInTouch3 > :global(.__wab_flex-container) > *,
.stayInTouch3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.stayInTouch3 > :global(.__wab_flex-container) > picture > img,
.stayInTouch3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.head12 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
}
.links3 {
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.links3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-left: calc(0px - 24px);
  width: calc(100% + 24px);
}
.links3 > :global(.__wab_flex-container) > *,
.links3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.links3 > :global(.__wab_flex-container) > picture > img,
.links3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 24px;
}
.x3 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__gzznv {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__gzznv > picture > img {
  object-fit: cover;
}
.fb3 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__gfYvj {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__gfYvj > picture > img {
  object-fit: cover;
}
.ig3 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__x9IAn {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__x9IAn > picture > img {
  object-fit: cover;
}
.li3 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__z6NSd {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__z6NSd > picture > img {
  object-fit: cover;
}
.th3 {
  background: rgba(255, 255, 255, 1);
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.img__m5FG0 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 24px;
  height: 24px;
  display: block !important;
  overflow: hidden;
  flex-shrink: 0;
}
.img__m5FG0 > picture > img {
  object-fit: cover;
}
.productsResources {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.productsResources > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.productsResources > :global(.__wab_flex-container) > *,
.productsResources > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.productsResources > :global(.__wab_flex-container) > picture > img,
.productsResources
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.resources5 {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.resources5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.resources5 > :global(.__wab_flex-container) > *,
.resources5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.resources5 > :global(.__wab_flex-container) > picture > img,
.resources5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.resources6 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.resources6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.resources6 > :global(.__wab_flex-container) > *,
.resources6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.resources6 > :global(.__wab_flex-container) > picture > img,
.resources6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.head15 {
  color: var(--token-pkN2y4iq-viG);
  font-family: var(--rubik);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  display: block;
  align-self: stretch;
  position: relative;
}
.items12 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
}
.items12 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.items12 > :global(.__wab_flex-container) > *,
.items12 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items12 > :global(.__wab_flex-container) > picture > img,
.items12
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.link116 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__qdfO {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link118 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__u4Eb {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.link117 {
  position: relative;
  width: 100%;
  min-width: 0;
}
.link__awqw {
  color: var(--token-vF1ggEL4NG72);
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
