.calloutSection {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 1440px;
  height: auto;
  justify-self: flex-start;
  background: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .calloutSection {
    width: 100%;
    min-width: 0;
  }
}
.desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1440px;
  background: url("https://site-assets.plasmic.app/92e9e3b69cc24a97602567f1c02ab9be.png")
    top 50% right 0% / cover no-repeat;
  position: relative;
  min-width: 0;
  padding: 96px 0px;
}
@media (max-width: 1024px) {
  .desktop {
    background: none;
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .desktop {
    height: auto;
    display: none;
  }
}
.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1216px;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.calloutSectionTextColumn__mGMnL:global(.__wab_instance) {
  position: relative;
}
.tablet {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 1440px;
  background: url("https://site-assets.plasmic.app/92e9e3b69cc24a97602567f1c02ab9be.png")
    top 50% right 0% / cover no-repeat;
  position: relative;
  min-width: 0;
  padding: 96px 0px;
}
@media (max-width: 1024px) {
  .tablet {
    background: none;
    display: flex;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .tablet {
    height: auto;
    display: none;
  }
}
.wrapper4 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1216px;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
}
.wrapper4 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.wrapper4 > :global(.__wab_flex-container) > *,
.wrapper4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper4 > :global(.__wab_flex-container) > picture > img,
.wrapper4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.calloutSectionTextColumn__wxx4L:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.mobile {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1440px;
  height: 392px;
  background: url("https://site-assets.plasmic.app/161c7d41e877858e4a1b6a10d710fff9.png")
      top 50% right 30px / contain no-repeat,
    var(--token-9T9NC2lhv1Ej);
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobile {
    width: 100%;
    height: auto;
    background: var(--token-9T9NC2lhv1Ej);
    min-width: 0;
    display: flex;
  }
}
.wrapper3 {
  width: 1216px;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
}
.wrapper3 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.wrapper3 > :global(.__wab_flex-container) > *,
.wrapper3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper3 > :global(.__wab_flex-container) > picture > img,
.wrapper3
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 64px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper3 > :global(.__wab_flex-container) {
    flex-direction: column;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper3 > :global(.__wab_flex-container) > *,
  .wrapper3 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .wrapper3 > :global(.__wab_flex-container) > picture > img,
  .wrapper3
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.calloutSectionTextColumn__kgZpF:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
