.footerBottom {
  height: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-width: 0;
}
.footerBottom > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.footerBottom > :global(.__wab_flex-container) > *,
.footerBottom > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.footerBottom > :global(.__wab_flex-container) > picture > img,
.footerBottom
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .footerBottom {
    display: flex;
  }
}
.wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 1024px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .wrapper > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (max-width: 1024px) {
  .wrapper > :global(.__wab_flex-container) > *,
  .wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .wrapper > :global(.__wab_flex-container) > picture > img,
  .wrapper
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .wrapper > :global(.__wab_flex-container) > *,
  .wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .wrapper > :global(.__wab_flex-container) > picture > img,
  .wrapper
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 32px;
  }
}
.copy {
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.copy > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.copy > :global(.__wab_flex-container) > *,
.copy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.copy > :global(.__wab_flex-container) > picture > img,
.copy > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
@media (max-width: 1224px) {
  .copy {
    padding-right: 20px;
  }
}
@media (max-width: 1024px) {
  .copy {
    padding: 0px;
  }
}
.logo {
  width: 114px;
  height: 24px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.img {
  object-fit: cover;
  max-width: 100%;
  transform: translate(0px, -0.3340000000000001px);
  width: 113.777px;
  height: 19.887px;
  display: block !important;
  overflow: hidden;
  position: absolute;
  cursor: auto;
  left: 0px;
  top: 3px;
}
.img > picture > img {
  object-fit: cover;
}
.desc {
  display: flex;
  position: relative;
  flex-direction: column;
}
.desc > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.desc > :global(.__wab_flex-container) > *,
.desc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desc > :global(.__wab_flex-container) > picture > img,
.desc > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.trademark {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  align-self: stretch;
  position: relative;
}
.trademark2 {
  font-family: var(--roboto);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  align-self: stretch;
  position: relative;
}
.security {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 32px 0px 0px;
}
.security > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.security > :global(.__wab_flex-container) > *,
.security > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.security > :global(.__wab_flex-container) > picture > img,
.security
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
@media (max-width: 1024px) {
  .security > :global(.__wab_flex-container) {
    justify-content: flex-start;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .security > :global(.__wab_flex-container) {
    justify-content: flex-start;
    margin-left: calc(0px - 16px);
    width: calc(100% + 16px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .security > :global(.__wab_flex-container) > *,
  .security > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .security > :global(.__wab_flex-container) > picture > img,
  .security
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 16px;
    margin-top: 0px;
  }
}
@media (max-width: 576px) {
  .security {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 576px) {
  .security > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - 36px);
    width: calc(100% + 36px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (max-width: 576px) {
  .security > :global(.__wab_flex-container) > *,
  .security > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .security > :global(.__wab_flex-container) > picture > img,
  .security
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 36px;
    margin-top: 0px;
  }
}
.left {
  display: flex;
  flex-direction: row;
  position: relative;
}
.left > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.left > :global(.__wab_flex-container) > *,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.left > :global(.__wab_flex-container) > picture > img,
.left > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (max-width: 576px) {
  .left {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
@media (max-width: 576px) {
  .left > :global(.__wab_flex-container) {
    flex-direction: column;
    justify-content: space-between;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 576px) {
  .left > :global(.__wab_flex-container) > *,
  .left > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .left > :global(.__wab_flex-container) > picture > img,
  .left
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.sslLink {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 87.91px;
  height: 40px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .sslLink {
    width: 100%;
    max-height: 40px;
    height: 100%;
    max-width: 87.91px;
    min-width: 0;
    min-height: 0;
  }
}
@media (max-width: 576px) {
  .sslLink {
    width: 80px;
  }
}
.ssl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: 40px;
  flex-shrink: 0;
}
.ssl > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .ssl {
    width: auto;
    height: auto;
  }
}
@media (max-width: 576px) {
  .ssl {
    width: 87.91px;
    max-width: auto;
    min-width: 87.91px;
    height: 40px;
    flex-shrink: 0;
  }
}
.smLink {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 87.91px;
  height: 40px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .smLink {
    width: 100%;
    height: 100%;
    max-width: 88px;
    min-width: 0;
    min-height: 0;
  }
}
@media (max-width: 576px) {
  .smLink {
    width: 104.28px;
    max-width: 104.28px;
    height: auto;
  }
}
.sm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 97.32px;
  height: 40px;
  flex-shrink: 0;
}
.sm > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .sm {
    width: auto;
    max-width: 88px;
    height: auto;
    max-height: 40px;
  }
}
@media (max-width: 576px) {
  .sm {
    max-height: 60px;
    width: 104.28px;
    height: 60px;
    max-width: 104.28px;
    flex-shrink: 0;
  }
}
.right {
  display: flex;
  flex-direction: row;
  position: relative;
}
.right > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
}
.right > :global(.__wab_flex-container) > *,
.right > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.right > :global(.__wab_flex-container) > picture > img,
.right > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 32px;
}
@media (max-width: 576px) {
  .right {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}
@media (max-width: 576px) {
  .right > :global(.__wab_flex-container) {
    flex-direction: column;
    justify-content: space-between;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 16px);
    height: calc(100% + 16px);
  }
}
@media (max-width: 576px) {
  .right > :global(.__wab_flex-container) > *,
  .right > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .right > :global(.__wab_flex-container) > picture > img,
  .right
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 16px;
  }
}
.trusteLink {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 125.96px;
  height: 40px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .trusteLink {
    width: auto;
    height: auto;
  }
}
@media (max-width: 576px) {
  .trusteLink {
    width: 110px;
  }
}
.truste {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 125.96px;
  height: 40px;
  flex-shrink: 0;
}
.truste > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .truste {
    width: auto;
    height: auto;
    max-width: 126px;
    max-height: 40px;
  }
}
@media (max-width: 576px) {
  .truste {
    max-width: 125.96px;
    width: 125.96px;
  }
}
.socLink {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 95.33px;
  height: 40px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .socLink {
    width: auto;
    height: auto;
  }
}
@media (max-width: 576px) {
  .socLink {
    width: 144.29px;
    height: 60px;
    flex-shrink: 0;
  }
}
.soc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.soc > picture > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .soc {
    width: auto;
    height: auto;
    max-width: 95.33px;
    max-height: 40px;
  }
}
@media (max-width: 576px) {
  .soc {
    height: 100%;
    max-height: none;
    width: 144.29px;
    min-width: 144.29px;
    min-height: 0;
  }
}
