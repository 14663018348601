.drawerItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: auto;
  cursor: auto;
  min-width: 0;
  padding: 10px 72px 10px 32px;
  border-left: 4px solid var(--token-9T9NC2lhv1Ej);
}
.drawerItem > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.drawerItem > :global(.__wab_flex-container) > *,
.drawerItem > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.drawerItem > :global(.__wab_flex-container) > picture > img,
.drawerItem
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.drawerItemactive {
  border-left-color: var(--token-pkN2y4iq-viG);
}
.drawerItemwithAccordion {
  padding: 10px 32px;
}
.drawerItemwithAccordion > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.drawerItemwithAccordion > :global(.__wab_flex-container) > *,
.drawerItemwithAccordion
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.drawerItemwithAccordion > :global(.__wab_flex-container) > picture > img,
.drawerItemwithAccordion
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 0px;
}
.drawerItemexpanded {
  box-shadow: 0px 2px 8px 0px #0f2a410f, 0px 1px 2px -1px #0f2a410a;
  border-left-width: 0px;
  padding: 0px;
}
.itemWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  cursor: pointer;
}
.itemWrapper > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.itemWrapperwithAccordion {
  width: 100%;
  min-width: 0;
}
.itemWrapperwithAccordion > :global(.__wab_flex-container) {
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.itemWrapperwithAccordion > :global(.__wab_flex-container) > *,
.itemWrapperwithAccordion
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.itemWrapperwithAccordion > :global(.__wab_flex-container) > picture > img,
.itemWrapperwithAccordion
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
  margin-top: 0px;
}
.itemWrapperexpanded {
  padding: 10px 32px;
  border-left: 4px solid var(--token-9T9NC2lhv1Ej);
}
.textWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.text {
  font-family: var(--rubik);
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
  color: var(--token-vF1ggEL4NG72);
  width: auto;
  height: auto;
  max-width: 100%;
  cursor: pointer;
  user-select: none;
}
.textactive {
  color: var(--token-pkN2y4iq-viG);
}
.textwithAccordion {
  width: auto;
  cursor: pointer;
}
.textexpanded {
  width: auto;
  cursor: pointer;
}
.drawerItem:hover .text {
  color: var(--token-gaowRD3VsD3w);
}
.icon {
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  flex-shrink: 0;
  display: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
}
.iconwithAccordion {
  display: flex;
}
.iconexpanded {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(90deg);
  display: flex;
}
.arrowIcon {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 6px;
  height: 10px;
  align-self: center;
  flex-shrink: 0;
}
.arrowIcon > picture > img {
  object-fit: cover;
}
.submenu {
  position: relative;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  display: none;
  padding: 16px 0px;
}
.submenu > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.submenu > :global(.__wab_flex-container) > *,
.submenu > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.submenu > :global(.__wab_flex-container) > picture > img,
.submenu
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.submenuexpanded {
  display: flex;
}
.mobileDrawerItemSubItem:global(.__wab_instance) {
  position: relative;
}
