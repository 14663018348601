.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: var(--token-F-isEiSy-7QO);
  min-width: 0;
}
.footergray {
  background: var(--token-7u3Fjf0ZO4pV);
}
.desktopVer {
  position: relative;
  max-width: 1440px;
  height: auto;
  background: url("https://site-assets.plasmic.app/72b4d3d28823943c6df4a8317965bf2b.svg")
      top 0% left 475px / 90% 90% no-repeat,
    var(--token-F-isEiSy-7QO);
  width: 100%;
  flex-direction: column;
  display: flex;
  min-width: 0;
  padding: 96px 0px;
}
.desktopVer > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.desktopVer > :global(.__wab_flex-container) > *,
.desktopVer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desktopVer > :global(.__wab_flex-container) > picture > img,
.desktopVer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1224px) {
  .desktopVer {
    background: url("https://site-assets.plasmic.app/72b4d3d28823943c6df4a8317965bf2b.svg")
        top 0% left 450px / 90% 90% no-repeat,
      var(--token-F-isEiSy-7QO);
  }
}
@media (max-width: 1024px) {
  .desktopVer {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .desktopVer {
    width: 390px;
    display: none;
  }
}
.desktopVergray {
  background: url("https://site-assets.plasmic.app/72b4d3d28823943c6df4a8317965bf2b.svg")
      top 0% left 475px / 90% 90% no-repeat,
    var(--token-7u3Fjf0ZO4pV);
}
.content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1216px;
  min-width: 0;
}
.content > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .content {
    width: auto;
  }
}
.footerTop___3Rx77:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
}
.footerBottom___9Pav:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
}
.tabletVer {
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-F-isEiSy-7QO);
  height: auto;
  min-width: 0;
  display: none;
  padding: 64px 32px;
}
.tabletVer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.tabletVer > :global(.__wab_flex-container) > *,
.tabletVer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.tabletVer > :global(.__wab_flex-container) > picture > img,
.tabletVer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
@media (max-width: 1024px) {
  .tabletVer {
    display: flex;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .tabletVer {
    width: 100%;
    min-width: 0;
    display: none;
    padding: 64px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .tabletVer > :global(.__wab_flex-container) {
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 64px);
    height: calc(100% + 64px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .tabletVer > :global(.__wab_flex-container) > *,
  .tabletVer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .tabletVer > :global(.__wab_flex-container) > picture > img,
  .tabletVer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 64px;
  }
}
.tabletVergray {
  background: var(--token-7u3Fjf0ZO4pV);
}
.footerTop__v1Owx:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.footerBottom__h0Voo:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.mobileVer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-F-isEiSy-7QO);
  height: auto;
  min-width: 0;
  padding: 64px 32px;
}
.mobileVer > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.mobileVer > :global(.__wab_flex-container) > *,
.mobileVer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mobileVer > :global(.__wab_flex-container) > picture > img,
.mobileVer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
@media (max-width: 1024px) {
  .mobileVer {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobileVer {
    width: 100%;
    min-width: 0;
    display: flex;
    padding: 64px 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobileVer > :global(.__wab_flex-container) {
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 64px);
    height: calc(100% + 64px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobileVer > :global(.__wab_flex-container) > *,
  .mobileVer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .mobileVer > :global(.__wab_flex-container) > picture > img,
  .mobileVer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 64px;
  }
}
.mobileVergray {
  background: var(--token-7u3Fjf0ZO4pV);
}
.footerTop__lxFnc:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.footerBottom__yNlit:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
