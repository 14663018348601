.footerTopDownload {
  height: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  max-width: 284px;
  position: relative;
  min-width: 0;
}
.footerTopDownload > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 64px);
  height: calc(100% + 64px);
}
.footerTopDownload > :global(.__wab_flex-container) > *,
.footerTopDownload > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.footerTopDownload > :global(.__wab_flex-container) > picture > img,
.footerTopDownload
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.desktop {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.desktop > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.desktop > :global(.__wab_flex-container) > *,
.desktop > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.desktop > :global(.__wab_flex-container) > picture > img,
.desktop
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .desktop {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .desktop {
    display: none;
  }
}
.download {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  align-self: stretch;
  position: relative;
  width: 100%;
  min-width: 0;
}
.row2 {
  height: auto;
  display: flex;
  position: relative;
  align-self: flex-start;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.row2 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.row2 > :global(.__wab_flex-container) > *,
.row2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row2 > :global(.__wab_flex-container) > picture > img,
.row2 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.play {
  box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
  background: rgba(255, 255, 255, 1);
  display: flex;
  position: relative;
  flex-grow: 0;
  width: 100%;
  flex-shrink: 1;
  height: auto;
  flex-direction: column;
  min-width: 120px;
  border-radius: 8px;
  padding: 8px;
}
.play > :global(.__wab_flex-container) {
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.play > :global(.__wab_flex-container) > *,
.play > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.play > :global(.__wab_flex-container) > picture > img,
.play > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 8px;
}
.play2 {
  width: 108px;
  height: 32px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.pngegg1 {
  background: url("https://site-assets.plasmic.app/222cb0846d5039495d11bfaa05e3d5d3.png")
    center center / cover no-repeat;
  transform: translate(0.38900000000000023px, 0.05799999999999983px);
  width: 110.82%;
  height: 253.12%;
  display: block;
  position: absolute;
  top: -15.63%;
  left: -5.56%;
}
.qr1 {
  background: url("https://site-assets.plasmic.app/6fae971d7634a661f2290043ca8a1336.png")
    center center / cover no-repeat;
  width: 108px;
  height: 108px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.appstore {
  box-shadow: 0px 2px 12px -2px #0f2a411a, 0px 1px 4px -2px #0f2a410f;
  background: rgba(255, 255, 255, 1);
  height: auto;
  display: flex;
  position: relative;
  flex-grow: 0;
  width: 100%;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 120px;
  border-radius: 8px;
  padding: 8px;
}
.appstore > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: calc(0px - 8px);
  height: calc(100% + 8px);
}
.appstore > :global(.__wab_flex-container) > *,
.appstore > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.appstore > :global(.__wab_flex-container) > picture > img,
.appstore
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 8px;
}
.apple {
  width: 108px;
  height: 32px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.pngegg2 {
  background: url("https://site-assets.plasmic.app/222cb0846d5039495d11bfaa05e3d5d3.png")
    center center / cover no-repeat;
  transform: translate(0.38900000000000023px, -0.0589999999999975px);
  width: 110.82%;
  height: 253.12%;
  display: block;
  position: absolute;
  top: -134.38%;
  left: -5.56%;
}
.qr2 {
  background: url("https://site-assets.plasmic.app/a872b576d0dca7322760f0c744edffcf.png")
    center center / cover no-repeat;
  width: 108px;
  height: 108px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.mobileTablet {
  height: auto;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  display: none;
}
.mobileTablet > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.mobileTablet > :global(.__wab_flex-container) > *,
.mobileTablet > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.mobileTablet > :global(.__wab_flex-container) > picture > img,
.mobileTablet
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .mobileTablet {
    display: flex;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .mobileTablet {
    display: flex;
  }
}
.download3 {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-pkN2y4iq-viG);
  display: block;
  align-self: stretch;
  position: relative;
}
.row6 {
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.row6 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.row6 > :global(.__wab_flex-container) > *,
.row6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.row6 > :global(.__wab_flex-container) > picture > img,
.row6 > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 16px;
}
.play6 {
  width: 135px;
  height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.play7 {
  background: url("https://site-assets.plasmic.app/222cb0846d5039495d11bfaa05e3d5d3.png")
    center center / cover no-repeat;
  transform: translate(0.38900000000000023px, 0.05799999999999983px);
  width: 110.82%;
  height: 253.12%;
  display: block;
  position: absolute;
  top: -15.63%;
  left: -5.56%;
}
.apple3 {
  width: 135px;
  height: 40px;
  display: block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.pngegg5 {
  background: url("https://site-assets.plasmic.app/222cb0846d5039495d11bfaa05e3d5d3.png")
    center center / cover no-repeat;
  transform: translate(0.38900000000000023px, -0.0589999999999975px);
  width: 110.82%;
  height: 253.12%;
  display: block;
  position: absolute;
  top: -134.38%;
  left: -5.56%;
}
